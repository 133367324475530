/*
 *    Copyright 2019 Django Cass
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 *
 */

import {Action} from "redux";

export const request = (action: string): string => `${action}_REQUEST`;
export const success = (action: string): string => `${action}_SUCCESS`;
export const failure = (action: string): string => `${action}_FAILURE`;

export const resetError = (action: string): Action => {
	return {
		type: `${action}_RESET`
	};
};