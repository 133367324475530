/*
 *    Copyright 2019 Django Cass
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 *
 */

import {RSAA} from "redux-api-middleware";

export default () => {
	return (next) => {
		return (action) => {
			const rsaa = action[RSAA];
			// check if this is a redux-api-middleware action
			if (rsaa) {
				// set content headers
				rsaa.headers = Object.assign({}, rsaa.headers, {
					"Content-Type": "application/json",
					"Accept": "application/json"
				})
			}
			// continue in FSA chain
			return next(action);
		}
	}
}