/*
 *    Copyright 2020 Django Cass
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 *
 */

import {useLocation} from "react-router-dom";
import {useMemo} from "react";

export default (): string => {
	const location = useLocation();

	return useMemo(() => {
		// if we're on /search, return "" instead of "search"
		if (location.pathname.split("/").length <= 2)
			return "";
		const idx = location.pathname.lastIndexOf("/");
		return location.pathname.substring(idx + 1);
	}, [location.pathname]);
}