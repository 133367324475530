/*
 *    Copyright 2020 Django Cass
 *
 *    Licensed under the Apache License, Version 2.0 (the "License");
 *    you may not use this file except in compliance with the License.
 *    You may obtain a copy of the License at
 *
 *        http://www.apache.org/licenses/LICENSE-2.0
 *
 *    Unless required by applicable law or agreed to in writing, software
 *    distributed under the License is distributed on an "AS IS" BASIS,
 *    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *    See the License for the specific language governing permissions and
 *    limitations under the License.
 *
 */

import {useLayoutEffect, useState} from "react";

interface WindowDimensions {
	width: number;
	height: number;
}

export default (): WindowDimensions => {
	const [size, setSize] = useState<WindowDimensions>({width: 0, height: 0});

	useLayoutEffect(() => {
		const updateSize = () => {
			setSize({width: window.innerWidth, height: window.innerHeight});
		};
		window.addEventListener("resize", updateSize);
		return () => {
			window.removeEventListener("resize", updateSize);
		};
	}, []);

	return size;
}